import React, { useEffect, useState, Suspense, lazy, useRef } from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    withRouter,
    useParams
} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import { getAppInfo, getGrpcClient, getGrpcPollingClient, getKeyByValue, getPageText, getTabToken, getUuidv4, redEscape } from "../../config/Common";
import { useSelector, useDispatch } from "react-redux";
import { reSetAuthData, reSetReportHere, setMultipleAuthData } from "../../redux/actions";
import GlobalDomainCons from "../../config/GlobalDomainCons";
import GlobalEventCons from "../../config/GlobalEventCons";
import GlobalMsgCons from "../../config/GlobalMsgCons";
import { ZiFetchRequest } from "../../protobuf/generated/ZiFetchService_grpc_web_pb";
import Loader from "../../components/Loader";
import MyConfig from "../../config/MyConfig";
import MyConstant from "../../config/MyConstant";

const Home = lazy(() => import('./Home'))
const Report = lazy(() => import('./Report'))
const ReportList = lazy(() => import('./ReportList'))

let intervalUserPolling;
let intervalPolling;
function OrgUserRoute(props) {
    let { path } = useRouteMatch();
    const { companyId } = useParams()
    path = path.replace(":companyId", companyId);
    let [languagePreference, setLanguagePreference] = useState("");
    const [activeCompanyColor, setActiveCompanyColor] = useState("");
    let [updateModal, setUpdateModal] = useState(false);
    let [memberDeactiveUpdated, setMemberDeactiveUpdated] = useState(false)
    let [accessVerify, setAccessVerify] = useState(false);
    let [accessVerifyLoader, setAccessVerifyLoader] = useState(true);
    let [channelId, setChannelId] = useState("");
    let [pollChannel, setPollChannel] = useState({});
    let [pollCall, setPollCall] = useState({});
    let [pollUser, setPollUser] = useState({});
    let [pollChannelUnreadCount, setPollChannelUnreadCount] = useState({});
    let [pollBoardChangesStatus, setPollBoardChangesStatus] = useState({});
    const auth = useSelector(state => state.authReducer);
    const reportHere = useSelector(state => state.reportHereReducer);

    let { requesterReached } = reportHere

    const dispatch = useDispatch();
    let activeCompanyIdRef = useRef("");
    let channelIdRef = useRef("");
    let messageIdRef = useRef("");
    let boardIdRef = useRef("");
    let memberListRef = useRef([]);
    let pollDeviceInfo = useRef({});
    let pollChannelUnreadCountRef = useRef({});


    useEffect(() => {
        pollDeviceInfo.current = getAppInfo()
        init();

        return () => {
            clearInterval(intervalUserPolling)
            clearTimeout(intervalPolling)
        }
    }, [])

    useEffect(() => {
        if (companyId !== "login" && companyId !== undefined) {
            if (auth.loginId) {
                let loginId = auth.loginId;
                activeCompanyIdRef.current = companyId
                dispatch(setMultipleAuthData({
                    activeCompany: companyId
                }))
                getCompanyDetails(loginId, companyId);
                pollSetup();
            } else {
                props.history.replace(getHomePage())
            }
        }
    }, [companyId])

    useEffect(() => {
        if (requesterReached) {

        }

    }, [requesterReached])

    useEffect(() => {
        if (memberDeactiveUpdated) {
            let url = getHomePage()
            dispatch(setMultipleAuthData({
                activeCompany: "",
                activeCompanyName: "",
                activeCompanyColor: "",
                activeCompanyDetails: "",
                activeCompanyLogo: "",
                feedId: ""
            }));

            setMemberDeactiveUpdated(false)
            props.history.push(url+"/report");
        }
    }, [memberDeactiveUpdated]);

    useEffect(() => {
        channelIdRef.current = channelId
    }, [channelId])

    function getHomePage() {
        let url = MyConstant.keyList.projectUrl;
        return url
    }

    async function init() {
    }

    function freqUserPoll() {
        intervalPolling = setTimeout(() => {
            pollSetup()
        }, 1000);
    }

    function pollSetup() {
        let { sessionId, loginId } = auth;
        let postJson = {
            rId: getUuidv4(),
            uId: loginId,
            sId: sessionId,
            stId: getTabToken(),
            orgType: GlobalMsgCons.orgTypeCompany
        };

        if (activeCompanyIdRef.current) {
            postJson.oId = activeCompanyIdRef.current
        }

        if (channelIdRef.current) {
            postJson.cId = channelIdRef.current
        }

        if (messageIdRef.current) {
            postJson.mId = messageIdRef.current
        }

        if (boardIdRef.current) {
            postJson.bId = boardIdRef.current
        }

        if (memberListRef.current.length) {
            postJson.memberList = memberListRef.current
        }

        if (pollDeviceInfo.current) {
            postJson.deviceInfo = pollDeviceInfo.current
        }

        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.pollSetDomain);
        request.setEvent(GlobalEventCons.globalPoll);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcPollingClient(props.grpcPollingClient).pollZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request.Please try again later');
                freqUserPoll();
            } else {
                freqUserPoll();

                let responseData = JSON.parse(response.getMessage());
                let { userSession, userPoll, user, channel, call, getChannelUnReadCountList, getBoardChangesStatus, areThereMessagesWaiting } = responseData;

                if (userSession) {
                    validateUserSession(JSON.parse(userSession));
                }
                if (userPoll) {
                    userPollFrequent(JSON.parse(userPoll))
                }
                if (channel) {
                    setPollChannel(JSON.parse(channel))
                }
                if (call) {
                    setPollCall(JSON.parse(call))
                }
                if (user) {
                    setPollUser(JSON.parse(user))
                }
                if (getChannelUnReadCountList && pollChannelUnreadCountRef.current !== getChannelUnReadCountList) {
                    pollChannelUnreadCountRef.current = getChannelUnReadCountList;
                    setPollChannelUnreadCount(JSON.parse(getChannelUnReadCountList))
                }
                if (getBoardChangesStatus) {
                    setPollBoardChangesStatus(JSON.parse(getBoardChangesStatus))
                }

                if (areThereMessagesWaiting) {
                    let { listOfMessageTypesWaiting } = JSON.parse(areThereMessagesWaiting);
                    if (listOfMessageTypesWaiting !== undefined) {
                        notifyUsersChanges(listOfMessageTypesWaiting)
                    }
                }
            }
        });
    }

    function validateUserSession(responseData) {
        let { listOfMessageTypesWaiting } = responseData;
        if (listOfMessageTypesWaiting !== undefined) {
            notifyUsersChanges(listOfMessageTypesWaiting)
        }
    }

    function userPollFrequent(responseData) {
        let { isValidSession, unreadInboxCount } = responseData;
        if (!isValidSession) {
            logOutApi()
        }
    }

    async function logOutApi() {
        let url = getHomePage()
        let loginId = auth.loginId;
        let deviceToken = auth.deviceToken;
        var sessionId = auth.sessionId;
        dispatch(reSetAuthData())
        dispatch(reSetReportHere())
        props.history.push(url+"/report");
        if (props.setReportSubmit) {
            props.setReportSubmit(false)
        }
        localStorage.removeItem(MyConfig.project_LocalStorage + "_userLogin");
        localStorage.removeItem(MyConfig.project_LocalStorage + "reportSubmit");

        let postJson = { reqId: getUuidv4(), userId: loginId, deviceToken: deviceToken ? deviceToken : "", sessionId: sessionId ? sessionId : "" };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.userSetDomain);
        request.setEvent(GlobalEventCons.userSignOutSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                console.log("responseData logout")
            }
        });

    }


    function getCompanyDetails(userId, organizationId) {
        let postJson = { reqId: getUuidv4(), userId, organizationId };
        const request = new ZiFetchRequest();
        request.setDomain(GlobalDomainCons.companySetDomain);
        request.setEvent(GlobalEventCons.organizationSetEvent);
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        getGrpcClient(props.grpcClient).sayZiFetch(request, {}, (err, response) => {
            if (response === null || response.getMessage() === GlobalMsgCons.invalidRequest) {
                console.warn('Invalid Request. Please try again later');
            } else {
                response = JSON.parse(response.getMessage());
                if (response && response.organization) {
                    let { organizationName, organizationType, themeColor, feedChannelId, requestFrom } = response.organization;
                    let controlFeature = {
                        audioEnable: true,
                        videoEnable: true,
                        qatEnable: true,
                        expensesEnable: true,
                        orgFeedbackEnable: true,
                        vsCodeEnable: true,
                        teqreqEnable: true,
                        dailyReportEnable: true,
                        appsEnable: false,
                        notesTeqitAiEnable: false,
                        userLastAccessEnable: false,
                        communityOrganization: false,
                        openMrsEnable: false
                    }
                    let controlFeatureDetails = response.controlFeaturesForOrganization;
                    if (controlFeatureDetails && Object.keys(controlFeatureDetails).length > 0) {
                        let { audioEnable, videoEnable } = controlFeatureDetails;
                        controlFeature.audioEnable = audioEnable;
                        controlFeature.videoEnable = videoEnable;
                        if (typeof (controlFeatureDetails.qat) !== "undefined") {
                            controlFeature.qatEnable = controlFeatureDetails.qat;
                        }
                        if (typeof (controlFeatureDetails.expenses) !== "undefined") {
                            controlFeature.expensesEnable = controlFeatureDetails.expenses;
                        }
                        if (typeof (controlFeatureDetails.orgFeedback) !== "undefined") {
                            controlFeature.orgFeedbackEnable = controlFeatureDetails.orgFeedback
                        }
                        if (typeof (controlFeatureDetails.teqreq) !== "undefined") {
                            controlFeature.teqreqEnable = controlFeatureDetails.teqreq
                        }
                        if (typeof (controlFeatureDetails.vsCode) !== "undefined") {
                            controlFeature.vsCodeEnable = controlFeatureDetails.vsCode
                        }
                        if (typeof (controlFeatureDetails.dailyReport) !== "undefined") {
                            controlFeature.dailyReportEnable = controlFeatureDetails.dailyReport
                        }
                        if (typeof (controlFeatureDetails.apps) !== "undefined") {
                            controlFeature.appsEnable = controlFeatureDetails.apps
                        }
                        if (typeof (controlFeatureDetails.notesTeqitAi) !== "undefined") {
                            controlFeature.notesTeqitAiEnable = controlFeatureDetails.notesTeqitAi
                        }
                        if (typeof (controlFeatureDetails.aiFeedChannel) !== "undefined") {
                            controlFeature.aiFeedChannelEnable = controlFeatureDetails.aiFeedChannel
                        }
                        if (typeof (controlFeatureDetails.userLastAccess) !== "undefined") {
                            controlFeature.userLastAccessEnable = controlFeatureDetails.userLastAccess
                        }
                        if (typeof (controlFeatureDetails.communityOrganization) !== "undefined") {
                            controlFeature.communityOrganization = controlFeatureDetails.communityOrganization
                        }
                        if (typeof (controlFeatureDetails.openMrs) !== "undefined") {
                            controlFeature.openMrsEnable = controlFeatureDetails.openMrs
                        }
                    }


                    let roleType = response.roleType;
                    let systemLevelRole = response.systemLevelRoleType

                    if (roleType === GlobalMsgCons.roleTypeMemberAdmin) {
                        roleType = "MemberAdmin"
                    } else if (roleType === GlobalMsgCons.roleTypeMember) {
                        roleType = "Member"
                    }
                    response.userId = "";

                    let companyOwnerId = response.organization.userId;
                    let activeUserType = "";
                    if (userId === companyOwnerId) {
                        activeUserType = "Admin"

                    } else {
                        activeUserType = roleType;

                    }

                    if(roleType === GlobalMsgCons.roleTypeOrgUser) {
                        setActiveCompanyColor(themeColor)
                        dispatch(setMultipleAuthData({
                            activeCompanyName: organizationName,
                            activeCompanyColor: themeColor,
                            activeCompanyDetails: JSON.stringify(response.organization),
                            feedId: feedChannelId,
                            activeUserType,
                            systemLevelRole,
                            controlFeature,
                            requestFrom
                        }))
                        setAccessVerify(true)
                    } else {
                        setAccessVerify(false)
                        setAccessVerifyLoader(false)
                    }

                } else {
                    setAccessVerify(false)
                    setAccessVerifyLoader(false)
                }
            }
        });
    }


    function askAlertModal(itemNewArr, item) {
        let askMessage = false
        itemNewArr.map((e => {
            let splitmsg = e.split("-");
            if (splitmsg.length > 0) {
                splitmsg.map((e) => {
                    if (e.toLowerCase() === "web" && item === "BuildRelease") {
                        askMessage = true;
                    }
                })
            }
        }))
        if (askMessage) {
            setUpdateModal(askMessage)
        }
    }

    function notifyUsersChanges(listOfMessageTypesWaiting) {
        listOfMessageTypesWaiting.map((item) => {

            let itemArr = []
            if (item.includes("::")) {
                itemArr = item.split("::");
                item = itemArr[0]

                /*build release alert */
                if (itemArr.length > 0) {
                    if (!updateModal) {
                        askAlertModal(itemArr, item)
                    }

                }
                /*build release alert */
            }

            switch (item) {
                case "ControlFeature":
                case "SystemLevelRoleType":
                    getCompanyDetails(auth.loginId, companyId)
                    break;
                case "sessionDeactivated":
                case "UserDeleted":
                    let url = getHomePage()
                    dispatch(reSetAuthData())
                    localStorage.removeItem(MyConfig.project_LocalStorage + "_userLogin");
                    props.history.push(url+"/report");
                    break;
            }
        })
    }

    function getThemeName() {
        let activeColor = "#2f3136";
        let returnTheme = "";

        if (auth.theme === "dark") {
            returnTheme = "theme-dark"
        } else {
            switch (activeColor) {
                case "#2f3136":
                    returnTheme = "theme-black"
                    break;
                case "#eeeeee":
                    returnTheme = "theme-white"
                    break;
                case "#9c27b0":
                    returnTheme = "theme-purple"
                    break;
                case "#2196f3":
                    returnTheme = "theme-blue"
                    break;
                case "#4caf50":
                    returnTheme = "theme-green"
                    break;
                case "#ff9800":
                    returnTheme = "theme-orange"
                    break;
                case "#607d8b":
                    returnTheme = "theme-gray"
                    break;
                case "#ee595a":
                    returnTheme = "theme-dark-orange"
                    break;
                case "#dd4197":
                    returnTheme = "theme-pink"
                    break;
            }
        }
        return returnTheme;
    }

    return <div className={`mobile-view ${getThemeName()}`}>
        <ToastContainer />
        <Suspense fallback={<Loader />}>
            <Switch>

                {
                    accessVerify ? <React.Fragment>
                        <Route exact path={`${path}`}>
                            <Home {...props} logOutApi={logOutApi} path={path} languagePreference={languagePreference} grpcClient={props.grpcClient} />
                        </Route>
                        <Route exact path={`${path}/report`}>
                            <ReportList {...props} logOutApi={logOutApi} path={path} languagePreference={languagePreference} grpcClient={props.grpcClient} />
                        </Route>
                        <Route exact path={`${path}/add-report`}>
                            <Report {...props} path={path} languagePreference={languagePreference} grpcClient={props.grpcClient} />
                        </Route>

                    </React.Fragment> : <React.Fragment>
                        {
                            accessVerifyLoader ? <Loader /> : <div className='no-access d-flex align-items-center justify-content-center h-100'>
                                No access rights were granted for this workspace. Please check your login details.
                            </div>
                        }
                    </React.Fragment>
                }

            </Switch>
        </Suspense>
    </div>;
}

export default withRouter(OrgUserRoute);